<template>
  <div class="navbar">
    <div class="searchInput">
      <img class="search-image" src="../../assets/home_nav_icon_search@2x.png">
      <input class="search-input" v-model="keyword" @keyup.13="toSearch" @blur="toSearch" placeholder="请输入关键字"/>
    </div>
    <span @click="cancel">取消</span>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    searchText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      keyword: this.searchText || ''
    }
  },
  methods: {
    toSearch() {
      if(!this.keyword) return
      this.$emit('search', this.keyword)
    },
    cancel() {
      this.keyword = ''
      this.$emit('cancel')
    },
  }
}
</script>

<style lang="less" scoped>
.navbar {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  border-bottom: 1px solid #E8E8E8;
}
.searchInput {
  width: calc(100% - 43px);
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .search-input {
    text-align: left;
    padding-left: 35px;
    font-size: 13px;
    width: 100%;
    height: 34px;
    color: #666;
    border: none;
    font-weight: 400;
    background: #F5F6F7;
    border-radius: 17px;
  }

  .search-image {
    z-index: 2;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 13px;
    top: 50%;
    margin-top: -7px;
  }
}
</style>
