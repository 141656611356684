<template>
  <div>
    <navbar @cancel="cancel" @search="search"/>
    <div v-if="goodsHistoryList.length" class="history">
      <div class="title">
        <span>历史搜索</span>
        <img alt="" src="../../assets/icon_delete@2x.png" @click="del"/>
      </div>
      <div class="content-box">
        <div v-for="(item,index) in goodsHistoryList" :key="index" class="list" @click="toList(item)">
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Message, MessageBox} from 'element-ui';
import navbar from '../../components/navbar-search'

export default {
  components: {
    navbar
  },
  data() {
    return {
      type: this.$route.query.type,
      goodsHistoryList: [],
      searchText: ""
    };
  },
  methods: {
    goodsSearch() {
      !this.goodsHistoryList.includes(this.searchText)
      && this.goodsHistoryList.push(this.searchText)
      && localStorage.setItem('goodsHistoryList', JSON.stringify(this.goodsHistoryList));
      this.$router.push({
        path: "/result",
        query: {
          keyword: this.searchText,
          orgType: this.type.toString()
        }
      });
    },
    //清空
    del() {
      MessageBox.confirm('确认清空历史记录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.goodsHistoryList = [];
        localStorage.removeItem('goodsHistoryList');
      })
    },
    // 点击搜索按钮
    search(data) {
      this.searchText = data
      this.goodsSearch();
    },
    //去搜索列表页
    toList(item) {
      this.searchText = item;
      this.goodsSearch();
    },
    cancel() {
      this.$router.push({
        path: "/"
      });
    },
  },
  mounted() {
    const goodsHistoryLists = localStorage.getItem('goodsHistoryList');
    console.log(goodsHistoryLists)
    if (goodsHistoryLists.length) this.goodsHistoryList = JSON.parse(goodsHistoryLists);
    console.log(this.goodsHistoryList)
  }
}
</script>
<style>
.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-dialog {
  margin-top: 0 !important;
  border-radius: 10px !important;
  width: 80% !important;
}

.el-dialog__title {
  font-size: 18px;
  line-height: 18px !important;
  color: #333 !important;
  margin-bottom: 10px;
  font-weight: 600;
}

.el-dialog__body {
  padding-top: 0 !important;
}

.el-message-box {
  width: 300px !important;
}
</style>
<style lang="less" scoped>
page {
  background-color: #FFFFFF;
}

.history {
  margin-top: 20px;
}

.title {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  color: #333;

  img {
    width: 24px;
    height: 24px;
  }
}

.content-box {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px 0 15px;

  .list {
    padding: 4px 10px;
    border-radius: 14px;
    background-color: #F7F8FA;
    font-size: 14px;
    color: #666;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}
</style>
